.bg1y {
    background: url("https://i.ibb.co/S0GTQ8Z/bg-select.png") #de5c2d;
    background-attachment: fixed;
}

.links__giulia {
    margin: 25px 0px 0px 0px !important;
    border-radius: 500px;
    width: 165px;
}

.br-25 {
    border-radius: 25px !important;
}

.links__subtitle {
    font-size: 12px;
    letter-spacing: 2px;
    color: #F57140;
    margin: 0 !important;
}

.links__title {
    font-size: 22px;
    line-height: 33px;
    font-weight: 800;
}

.links__date {
    font-size: 16px;
    color: #F57140 !important;
}

.links__text {
    font-size: 14px;
}

.disabled {
    opacity: 0.4 !important;
    background-color: #F57140 !important;
    border: transparent;
    border-radius: 25px;
    font-size: 14px;
    font-weight: 700;
    padding: 12px;
    width: 100%;
    transition: ease 0.2;
}

.a4 {
    background-color: #F57140 !important;
}

.link-sub__title {
    font-size: 26px;
    font-weight: 700;
}

.links__button {
    background-color: #F57140;
    border: transparent;
    border-radius: 25px;
    font-size: 14px;
    font-weight: 700;
    padding: 12px;
    width: 100%;
    transition: ease 0.2;
}

.btn-telegram {
    background-color: #2AABEE;
    border: transparent;
    border-radius: 25px;
    font-size: 14px;
    font-weight: 700;
    padding: 12px;
    width: 320px;
    transition: ease 0.2;
}

.btn-telegram:hover {
    background-color: #2AABEE;
    opacity: 0.85;
}

.btn-youtube {
    background-color: #FBD54E;
    border: transparent;
    border-radius: 25px;
    font-size: 14px;
    font-weight: 700;
    padding: 12px;
    width: 320px;
    transition: ease 0.2;
}

.btn-youtube:hover {
    background-color: #FBD54E;
    opacity: 0.85;
}

.curso__logo {
    margin-top: -20px;
    width: 250px;
}








.btn-youtube {
    background-color: #f72b2b;
    border: transparent;
    border-radius: 25px;
    font-size: 14px;
    font-weight: 700;
    padding: 12px;
    width: 320px;
    transition: ease 0.2;
}

.btn-youtube:hover {
    background-color: #f72b2b;
    opacity: 0.85;
}

.btn-wpp {
    background-color: #32da38;
    border: transparent;
    border-radius: 25px;
    font-size: 14px;
    font-weight: 700;
    padding: 12px;
    width: 320px;
    transition: ease 0.2;
}

.btn-wpp:hover {
    background-color: #02ab08;
    opacity: 0.85;
}

.btn-fb {
    background-color: #2453df;
    border: transparent;
    border-radius: 25px;
    font-size: 14px;
    font-weight: 700;
    padding: 12px;
    width: 320px;
    transition: ease 0.2;
}

.btn-fb:hover {
    background-color: #2453df;
    opacity: 0.85;
}



.btn-tlg {
    background-color: #4297ff;
    border: transparent;
    border-radius: 25px;
    font-size: 14px;
    font-weight: 700;
    padding: 12px;
    width: 320px;
    transition: ease 0.2;
}

.btn-tlg:hover {
    background-color: #4297ff;
    opacity: 0.85;
}










@media(max-width: 1080px) {
    .bg1y {
        padding-bottom: 35px;
    }
    .links__card {
        width: 85%;
        padding: 30px 30px !important;
        margin: 25px 25px 0px !important;
    }
    .insta__link {
        margin: 0px 10px -20px 10px !important;
    }
    .bg1x {
        padding-bottom: 35px;
    }
    .links__footer {
        text-align: center !important;
    }
}
.obg__page {
    min-height: 100vh;
}

.btn__cta__obg {    
    background-color: #18BF5B;
    font-weight: 700;
    border: transparent;
    min-height: 40px;
    padding: 13px 30px;
    border-radius: 8px;
}
.aula__banner {
    background: url(https://i.ibb.co/6PR4zfd/bga.png) no-repeat;
    background-position: top;
    background-size: cover;
    min-height: 80vh;
}

.bg1y {
    background: url("https://i.ibb.co/S0GTQ8Z/bg-select.png") #de5c2d;
    background-attachment: fixed;
}

.bglighty {
    background: url("https://i.ibb.co/LtnfVh2/bgx2.png");
    background-attachment: fixed;
}

.aula__badge {
    border: #FFB28C 2px solid;
    border-radius: 4px;
    padding: 5px 20px;
}

.aula__time {
    color: white;
    font-weight: 600;
    margin: 50px 0px 0px 5px !important;
}

.aula__subtitle {
    color: white;
    font-weight: 300;
    margin: 0px 0px 0px 0px !important;
}

.aula__input {
    font-size: 14px;
    padding-left: 25px;
    height: 50px;
    border-radius: 8px;
}

.aula__ctabtn {
    background-color: #18BF5B;
    font-weight: 700;
    border: transparent;
    height: 50px;
    border-radius: 8px;
}

.aula__ctabtn:hover, .aula__ctabtn:active, .aula__ctabtn:focus {
    background-color: #18BF5B;
    opacity: 0.9;
}

.aula__learn {
    font-size: 30px;
    color: #F57140;
    font-weight: 700;
}

.aula__learn__text {
    font-size: 18px;
    color: #666;
}

.aula__learn__text__mb {
    margin-bottom: 35px;
}

.margin__left__aula {
    margin-left: 10px;
}

.aula__icon {
    width: 45px;
    height: 25px;
}

.aulas__padding__fix {
    padding: 20px 100px !important;
}

.aulas__quemsou {
    color: white;
    font-size: 30px;
    font-weight: 700;
}

.aulas__description {
    color: white;
    font-weight: 300;
}

.aulas__separator {
    width: 83px;
    height: 5px;
}

@media(max-width: 1200px) {
    .aulas__padding__fix {
        padding: 20px 30px !important;
    }
    .aula__learn {
        text-align: center;
        margin-top: 25px;
    }
    .aula__ctabtn {        
        min-height: 60px;        
    }
}